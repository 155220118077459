export default {
    data(){
        return {
            dd_task_statuses : [
                { label: "Creato" , value: 0, color: "primary"},
                { label: "Esecuzione richiesta" , value: 1, color: "info"},
                { label: "Running" , value: 2, color: "warning"},
                { label: "Terminata OK" , value: 3, color: "success"},
                { label: "Fallita" , value: 4, color: "error"}
            ]
        }
    },
    methods: {
        getTaskStatusLabel(stat){
            let found = "";
            this.dd_task_statuses.forEach(s => {
                if (s.value === stat){
                    found = s.label;
                }
            })
            return found;
        },
        getTaskStatusColor(stat){
            let found = "";
            this.dd_task_statuses.forEach(s => {
                if (s.value === stat){
                    found = s.color;
                }
            })
            return found;
        },
        getTaskStatus(stat){
            let found = {label: "Sconosciuto", color: "", value: -1};
            this.dd_task_statuses.forEach(s => {
                if (s.value === stat){
                    found = s;
                }
            })
            return found;
        }
    }
}
